import React from "react"
import { Helmet } from "react-helmet"

import { Layout } from "../components/Layout"
import {
  AttributeSection,
  CTAButton,
  GetStartedButton,
  LearnMoreButton,
  Faq,
  CompareSection,
  AutoScrollBanner,
  VimeoPlayer
} from "../commonComponents"
import {
  IntegrationsMiddleSectionImageLeft,
  IntegrationsMiddleSectionImageRight,
} from "../integrationsMiddleSection"
import {
  DefaultDescription1,
  IntegrationDescriptionRow,
  IntegrationFirstCol,
  IntegrationIntroSection,
  IntegrationSecondCol,
  IntegrationTitleRow,
} from "../integrationUtils/introIntegrationUtils"
import { capitalizeFirstLetter } from "../utils"
import {
  IntegrationDarkSection,
  IntegrationHeroSection,
} from "../integrationsUtils"

export default function Zendesk() {
  const SERVICE_NAME = "zendesk"
  const BACKGROUND_SRC = `/img/${SERVICE_NAME}-bg.png`
  const BACKGROUND_WEB_POSITION = "100%"
  const BACKGROUND_MOBILE_POSITION = "35% 50px"
  const HeroVideo = () => {
    return (
      <div className="video-player-wrapper" >
        <VimeoPlayer url="https://vimeo.com/1062541967" autoplay="true" loop="true" />
      </div>
    )
  }
  const ServiceIntroTitle = () => (
    <IntegrationTitleRow>
      <>
        Level up your <br />
        <span className="title-fucsia-color">
          {capitalizeFirstLetter(SERVICE_NAME)} agents
        </span>
      </>
    </IntegrationTitleRow>
  )
  const ServiceIntroDescription = () => (
    <IntegrationDescriptionRow>
      <DefaultDescription1 />
    </IntegrationDescriptionRow>
  )
  const ZendeskIntroWorksWith = () => (
    <div className="intro-integration-work-with">
      Works with
      <br />
      <img
        src="/img/zendesk_support_vertical.svg"
        className="img-fluid intro-integration-work-with-image mt-2 mr-2"
        alt="zendesk support"
      />
      <img
        src="/img/zendesk_guide_vertical.svg"
        className="img-fluid intro-integration-work-with-image mt-2"
        alt="zendesk guide"
      />
    </div>
  )
  const ServiceIntroFirstCol = () => (
    <IntegrationFirstCol name={SERVICE_NAME}>
      <ServiceIntroTitle />
      <ServiceIntroDescription />
      <div className="my-4">
        <CTAButton to="/get-a-demo" label="Book demo" />
      </div>
      <ZendeskIntroWorksWith />
    </IntegrationFirstCol>
  )
  const ServiceIntroSecondCol = () => (
    <IntegrationSecondCol name={SERVICE_NAME}></IntegrationSecondCol>
  )
  const ServiceIntroSection = () => (
    <IntegrationIntroSection
      backgroundSrc={BACKGROUND_SRC}
      backgroundWebPosition="210%"
      backgroundMobilePosition="35% 50px"
    >
      <ServiceIntroFirstCol />
      <ServiceIntroSecondCol />
    </IntegrationIntroSection>
  )
  return (
    <Layout
      keywords="zendesk chatbot, Zendesk and Jira integration, Zendesk Agent Assist, Zendesk Assist, Zendesk AI"
      title="Boost Zendesk Support with AI Agent Assist and GPT Chatbot | IrisAgent"
      description="Discover powerful AI chatbot and agent assist for Zendesk to automate ticket resolution, enhance agent efficiency & provide valuable customer insights. Click here to book a demo now!"
    >
    <Helmet htmlAttributes={{ lang: 'en' }}>
        <link rel="canonical" href="https://irisagent.com/zendesk/" />
      </Helmet>
      {/* <!-- ======= Hero Section ======= --> */}
      <IntegrationHeroSection
        serviceName={SERVICE_NAME}
        titleIntegration={
          <>
            Up level your <br />
            <span className="title-fucsia-color">
              {capitalizeFirstLetter(SERVICE_NAME)} operations
            </span>
          </>
        }
        descriptionIntegration={
          <>
            <span>
              Resolve support tickets faster,
              <br/>improve agent performance, and deflect
              <br/>more than 40% of tickets with IrisGPT
              <br/>chatbot and Zendesk integration
            </span>
          </>
        }
        sectionBackgroundSrc={BACKGROUND_SRC}
        sectionBackgroundWebPosition={BACKGROUND_WEB_POSITION}
        sectionBackgroundMobilePosition={BACKGROUND_MOBILE_POSITION}
        workWithRow={<ZendeskIntroWorksWith />}
      />
      {/* <!-- End Hero --> */}

      <AutoScrollBanner />

      <div className={"col-lg-12 col-md-12 col-sm-24 col-xs-24"} style={{paddingLeft: "12%", paddingRight: "12%"}}>
         <HeroVideo />
      </div>

      {/*  ======= Cta Section =======  */}
      <IntegrationDarkSection
        label={
          <span className="integration-dark-section-title">
            Scale your{" "}
            <span className="title-fucsia-color">customer support team</span>
          </span>
        }
        button={
          <CTAButton
            to="https://www.zendesk.com/marketplace/apps/support/244660/irisagent/"
            label={`FIND US ON ${SERVICE_NAME.toUpperCase()} MARKETPLACE`}
            target="_blank"
          />
        }
        firstColClassName="col-md-8"
        secondColClassName="col-md-4"
        buttonWebFlexAlignment="end"
      />
      {/*  End Cta Section  */}
      {/* <!-- ======= Middle Section ======= --> */}
      <IntegrationsMiddleSectionImageLeft
        mainImage="/img/zendeskMiddle1.webp"
        imageTextMiniature="/img/zendeskMiniature.webp"
        backgroundSectionColor="#FFFFFF"
        descriptionTextTitle={<>Resolve common tickets automatically with AI</>}
        descriptionTextContent={
          <>
            Access appropriate resolutions based on similar tickets and
            knowledge base articles from within Zendesk.
          </>
        }
      />
      <IntegrationsMiddleSectionImageRight
        mainImage="/img/irisgpt.svg"
        imageTextMiniature="/img/zendeskMiniature.webp"
        backgroundSectionColor="#F9F9F9"
        descriptionTextTitle={
          <>
            Deflect 40% of tickets and chats with Zendesk AI and IrisGPT Chatbot integration
          </>
        }
        descriptionTextContent={
          <>
            Trained on the world's largest large language models, IrisGPT generates multilingual, trustworthy, and highly accurate answers integrated in your Zendesk chatbot
          </>
        }
      />
      <IntegrationsMiddleSectionImageLeft
        mainImage="/img/zendeskMiddle3.webp"
        imageTextMiniature="/img/zendeskMiniature.webp"
        backgroundSectionColor="#FFFFFF"
        descriptionTextTitle={
          <>
            Automate ticket tagging,
            routing and prioritization within Zendesk
          </>
        }
        descriptionTextContent={
          <>
            IrisAgent discovers tags customized to your domain and automatically
            tags new tickets. Get rid of manual and inaccurate ticket tagging.
          </>
        }
      />
      <IntegrationsMiddleSectionImageRight
        mainImage="/img/zendeskMiddle2.webp"
        imageTextMiniature="/img/zendeskMiniature.webp"
        backgroundSectionColor="#F9F9F9"
        descriptionTextTitle={
          <>
            Bring product and
            <br /> customer context to
            <br /> your agents
          </>
        }
        descriptionTextContent={
          <>
            Get to know customers’ business priorities and usage history to
            better-empathize with their experiences. Leverage internal
            operational and product signals to instantly discover the source of
            user issues and link them with known product or process bugs in Jira.
          </>
        }
      />
      {/* <!-- End Middle Section --> */}
      {/* <!-- ======= Counts Section ======= --> */}
      <CompareSection theme="dark" service="Zendesk"/>
      {/* <!-- End Counts Section --> */}

      {/* //   <!-- ======= Attribute Section ======= --> */}
      <AttributeSection />
      {/* //   <!-- ======= END -- Attribute Section ======= --> */}
                    <section
        className="d-flex align-items-center sub-section-3-container"
        // style={{ margin: "100px" }}
      >
        <div className="">
          <div className="row">
            <div className="col-sm order-1 order-lg-1">
              <div style={{ marginLeft: "40px" }}>
                <p className="sub-section-3-h1">Any questions?</p>
                <p className="sub-section-3-h2"> We got you.</p>
                <img
                  src="/img/security-5.svg"
                  className="img-fluid"
                  alt="secure"
                />
              </div>
            </div>
            <div className="col-sm pt-5 pt-lg-0 order-2 order-lg-2 d-flex flex-column justify-content-center">
              <div id="accordion">
                <Faq
                  id="faq1"
                  question="How does IrisAgent enhance customer service for Zendesk customers?"
                  answer="IrisAgent enhances customer service by providing fast, accurate, and personalized responses across various communication channels. Its advanced capabilities, including AI Agent Assist enable it to comprehend human language nuances, and assist in ensuring a seamless and cohesive brand experience for Zendesk customers and their customers."
                />
                <Faq
                  id="faq2"
                  question="Can IrisAgent integrate with Zendesk's existing AI capabilities?"
                  answer="Yes, IrisAgent seamlessly integrates with Zendesk solutions platform, complementing its existing AI capabilities. Through this integration, Zendesk customers can leverage the power of IrisAgent to enhance their customer engagement strategies and drive operational efficiency."
                />
                <Faq
                  id="faq3"
                  question="How does IrisAgent AI resolve support tickets in Zendesk faster?"
                  answer="IrisAgent Agent Assist AI and IrisGPT in Zendesk saves agents' time and effort by automating responses to common, repetitive support queries. It also provides agents with real-time, context-aware response suggestions, enabling them to resolve complex issues faster and more accurately. This results in improved customer satisfaction and reduced ticket resolution times."
                />
                <Faq
                  id="faq4"
                  question="How does IrisAgent AI automate ticket tagging, routing, and sentiment analysis in Zendesk?"
                  answer="IrisAgent automatically identifies tags specific to your domain or industry and applies them to new tickets without agent intervention. Our AI also analyzes Zendesk tickets for language cues that reveal the sentiment. IrisAgent routes tickets to the right agent based on their domain and expertise. It uses built-in analytics to identify those product features with the most negative reactions so that your support team agents and product teams can better understand the source of the most 'how-to' tickets."
                />
                <Faq
                  id="faq5"
                  question="How can Zendesk customers get started with IrisAgent?"
                  answer="It takes just a few minutes to get started with IrisAgent. Zendesk customers can install the IrisAgent app from the Zendesk marketplace and start using it right away. IrisAgent is designed to be user-friendly and intuitive, so you can start leveraging its advanced AI capabilities without any technical expertise."
                />
                <Faq
                  id="faq6"
                  question="What channels does IrisAgent support for communication?"
                  answer="IrisAgent supports multiple communication channels, including email, chat, and social media platforms. Its omnichannel approach enables Zendesk customers to engage and connect with their customers wherever they are, providing a unified experience across all channels."
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- ======= Cta Section ======= --> */}
      {/* <ContactSection></ContactSection> */}
      {/* <!-- End Cta Section --> */}
    </Layout>
  )
}
